export const breakPoint = {
  tiny: 320,
  small: 768,
  medium: 990,
  large: 1280,
  xlarge: 1600,
  xxlarge: 1920,
  xxxlarge: 2560,
};

export const Theme = {
  breakPoint: {
    small: '700px',
    medium: '1000px',
    large: '1200px',
  },

  device: {
    smobile: {
      over: `(min-width: ${breakPoint.tiny + 1}px)`,
      under: `(max-width: ${breakPoint.tiny}px)`,
    },
    mobile: {
      over: `(min-width: ${breakPoint.small + 1}px)`,
      under: `(max-width: ${breakPoint.small}px)`,
    },
    tablet: {
      over: `(min-width: ${breakPoint.medium + 1}px)`,
      under: `(max-width: ${breakPoint.medium}px)`,
    },
    desktop: {
      over: `(min-width: ${breakPoint.large + 3}px)`,
      under: `(max-width: ${breakPoint.large}px)`,
    },
    xdesktop: {
      over: `(min-width: ${breakPoint.xlarge + 1}px)`,
      under: `(max-width: ${breakPoint.xlarge}px)`,
    },
  },

  retina: '(-webkit-min-device-pixel-ratio: 2) and (min-resolution: 192dpi)',
  retinaSmall: '(-webkit-min-device-pixel-ratio: 2)',

  orientation: {
    portrait: 'only screen and (orientation: portrait)',
    landscape: 'only screen and (orientation: landscape)',
  },

  accentColor: {
    primary: '#E60576',
    secondary: '#367BFF',
    placeholder: '#a5b2be',
    body: '#969ea7',
    success: '#00BE9D',
    error: '#EB5163',
    warning: '#F49B00',
  },

  btnBaseColor: {
    primary: '#367BFF',
    secondary: '#171F1F',
    edit: '#38C2B7',
    pink: '#E60576',
  },

  btnHoverColor: {
    primary: '#014AD5',
    secondary: '#2A2E2E',
    edit: '#30DFD1',
    pink: '#B70860',
  },

  btnActiveColor: {
    primary: '#0045C9',
    secondary: '#3F4846',
    edit: '#00FFEB',
    pink: '#7C0842',
  },

  textColor: {
    superWhiteGrey: '#F9F9F9',
    whiteGrey: '#9EA0A5',
    whiteGreyDark: '#3E3F42',
    edit: '#2A2E2E',
    grey: '#1A1E1E',
    entitled: '#37C2B7',
  },

  bgColor: {
    blackUltraDark: '#0000003b',
    blackDark: '#111414',
    black: '#141B1B',
    blackLight: '#171F1F',
    blackMoreDarkest: '#1E1E20',
    greyDarkest: '#212626',
    greyDarker: '#212A2A',
    greyDark: '#2A2E2E',
    grey: '#3F4846',
    greyLighter: '#6B6F6E',
    greyLight: '#AAA',
    greyLightest: '#96A3A1',
    greyWhite: '#AAAAAA',
    whiteDark: '#C3D0CD',
    whiteLight: '#D6D6D6',
    superWhiteLight: '#ECECEC',
    ultraWhiteLight: '#F6F6F6',
    white: '#FFFFFF',
    greyBackground: '#31343C',
    greyPlaceholder: '#626878',
  },

  boxShadow: {
    default: '-10px -10px 25px 0 rgba(255,255,255,0.07), 11px 14px 21px 0 rgba(20,23,26,0.69);',
    header: '-11px -7px 22px 0 #2A2E2E, 2px 11px 19px 0 #1E1E20',
  },

  linearBg: {
    default: 'linear-gradient(51.43deg, #212626 0%, #171F1F 100%);',
  },

  typo: {},

  size: {},
};
