import React, { Suspense, useContext, useEffect, useState } from 'react';
import { StyleSheetManager, ThemeProvider } from 'styled-components';
import { Route, Routes } from 'react-router-dom';

import { PageLoader } from './components/page-loader';
import { AcceptInvitationPage } from '../pages/invitations/accept-invitation.page';
import { handleAuthentication, keycloak, updateToken } from '../keycloak';
import { MixpanelContext } from '../wrappers/mixpanel';
import { Theme } from './theme';
import './i18n';
import { OnBoardingPage } from '../pages/on-boarding/on-boarding.page';
import { MarketplaceLandingPageRouter } from '../pages/marketplace/landing-page.router';

/**
 * Lazy load the Dashboard to redirect faster if user is not logged in
 */
const Dashboard = React.lazy(() =>
  import('./Dashboard').then((module) => ({ default: module.Dashboard })),
);

const windowVisibilityObserver = () => {
  if (!document.hidden && keycloak.isTokenExpired()) {
    updateToken();
  }
};

export const App = () => {
  const { init: initMixpanel } = useContext(MixpanelContext);
  const [isReady, setIsReady] = useState(false);

  useEffect(() => {
    initMixpanel();
  }, [initMixpanel]);

  useEffect(() => {
    const token = localStorage.getItem('kc_token');
    const refreshToken = localStorage.getItem('kc_refreshToken');

    keycloak
      .init({
        onLoad: 'check-sso',
        checkLoginIframe: false,
        token: token || undefined,
        refreshToken: refreshToken || undefined,
      })
      .then((authenticated: boolean) => {
        handleAuthentication(authenticated);
        setIsReady(true);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  /**
   * Check the authorization status when Producer comes back into view
   */
  useEffect(() => {
    document.addEventListener('visibilitychange', windowVisibilityObserver);
    return () => document.removeEventListener('visibilitychange', windowVisibilityObserver);
  }, []);

  /**
   * Refresh the access token before its expiration to keep the user logged in
   * while he uses the application. Auto-refresh is stopped when the application
   * is not into view.
   */
  useEffect(() => {
    let interval = setInterval(updateToken, 3_540_000);

    document.addEventListener('visibilitychange', () => {
      if (document.hidden) {
        clearInterval(interval);
      } else {
        interval = setInterval(updateToken, 3540000);
      }
    });

    return () => clearInterval(interval);
  }, []);

  return (
    <StyleSheetManager enableVendorPrefixes>
      <ThemeProvider theme={Theme}>
        {!isReady ? (
          <PageLoader />
        ) : (
          <Routes>
            <Route path="/accept-invite/:invitationId" element={<AcceptInvitationPage />} />
            <Route path="/marketplace/*" element={<MarketplaceLandingPageRouter />} />
            <Route path="/onboarding/*" element={<OnBoardingPage />} />
            <Route
              path="/space/:spaceId/*"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Dashboard />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<PageLoader />}>
                  <Dashboard />
                </Suspense>
              }
            />
          </Routes>
        )}
      </ThemeProvider>
    </StyleSheetManager>
  );
};
