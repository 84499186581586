import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';

import { useInvitation } from '../../features/invitations/hooks/use-invitation';
import { PageLoader } from '../../app/components/page-loader';
import { OnBoardingLayout } from '../on-boarding/on-boarding.layout';
import { ElevatedButton } from '../../components/buttons';
import { api } from '../../api';
import { fetchCurrentUser } from '../../features/user/user.slice';
import { fetchSpaces } from '../../features/spaces/stores/space.slice';
import { Card } from '../../components/on-boarding/Card';
import { authStore } from '../../core/stores/auth-store';

const errorTexts = {
  401: 'This invitation is not for you. Please log in with the same email used for this invitation.',
  404: 'This invitation is expired. Please request a new one to the administrator of this space.',
  500: 'Something bad happen but we are not sure what. Please contact the support team at this address: <a href="mailto:support@the-producer.io">support@the-producer.io</a>.',
};

export const AcceptInvitationPage: React.FC = observer(() => {
  const { invitationId } = useParams();

  const navigate = useNavigate();
  const user = authStore.currentUser;
  const { invitation, isLoading: isInvitationLoading, error } = useInvitation(invitationId!);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetchCurrentUser();
  }, []);

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchSpaces({ userId: user._id });
  }, [user]);

  if (isInvitationLoading || !user) {
    return <PageLoader />;
  }

  const handleAcceptInvitation = async () => {
    if (isLoading) return;
    setIsLoading(true);

    await api.post(`/invitations/${invitationId}/accept`).then(({ data }) => {
      const href = `/space/${data.spaceId}`;
      navigate(href);
    });

    setIsLoading(false);
  };

  const renderContent = () => {
    if (error) {
      // @ts-ignore
      let errorText = errorTexts[error.status];

      if (!errorText) {
        errorText = error;
      }

      return (
        <Card>
          <Content dangerouslySetInnerHTML={{ __html: errorText }} />
        </Card>
      );
    }

    if (invitation) {
      return (
        <Card
          footer={
            <ElevatedButton
              text="Accept invitation"
              isLoading={isLoading}
              onClick={handleAcceptInvitation}
              isFullWidth
            />
          }
        >
          Hello {user!.firstName},<br />
          {invitation.inviter.firstName} invited you to join {invitation.space.name}&apos;s space.
        </Card>
      );
    }
  };

  return (
    <OnBoardingLayout>
      <Container>{renderContent()}</Container>
    </OnBoardingLayout>
  );
});

const Container = styled.div`
  max-width: 37rem;
`;

const Content = styled.p`
  text-align: center;
  line-height: 2rem;

  b {
    white-space: nowrap;
  }
`;
