import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styled from 'styled-components';

import { OutlineArrowRight } from '../../../components/icons/outline-arrow-right';
import { useResponsive } from '../../../hooks/useResponsive';
import { SettingsMenu } from './settings-menu';
import { breakPoint } from '../../../app/theme';

type MenuOptions = {
  key: string;
  label: string;
  href: string;
};

type SettingsLayoutProps = React.PropsWithChildren<{
  title: string;
  menu: MenuOptions[];
  displayBackButton?: boolean;
}>;

export const SettingsPageLayout: React.FC<SettingsLayoutProps> = ({
  children,
  title,
  menu,
  displayBackButton,
}) => {
  const navigate = useNavigate();
  const { spaceId } = useParams() as { spaceId: string };
  const location = useLocation() as { state: { prevPath: string } };
  const { isMobile } = useResponsive();

  const goBack = () => {
    if (location.state && location.state.prevPath) {
      navigate(location.state.prevPath);
    } else if (spaceId) {
      navigate(`/space/${spaceId}`);
    } else {
      navigate(`/`);
    }
  };

  return (
    <Wrapper>
      {!isMobile && (
        <NavigationBar>
          <Header>
            {displayBackButton && (
              <BackButton onClick={goBack}>
                <OutlineArrowRight />

                <span>Back</span>
              </BackButton>
            )}

            <SettingsTitle>{title}</SettingsTitle>
          </Header>

          <MenuContainer>
            <SettingsMenu items={menu} />
          </MenuContainer>
        </NavigationBar>
      )}

      <Screens>{children}</Screens>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 2.8rem;
  max-height: 100%;
  height: 100%;
`;

const NavigationBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  position: relative;
  height: 100%;

  &:after {
    content: '';
    width: 1px;
    height: calc(100%);
    position: absolute;
    right: 0;
    bottom: 0;
    background-color: #31343c;
  }

  @media (min-width: ${breakPoint.medium}px) {
    width: unset;
    min-width: 27.2rem;
  }
`;

const Screens = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 1.6rem;

  @media (min-width: ${breakPoint.medium}px) {
    padding-right: 2.4rem;
  }
`;

const MenuContainer = styled.div`
  padding: 0 2.4rem;
  flex: 1;
  width: 100%;
`;

const SettingsTitle = styled.h1`
  font-style: normal;
  font-weight: 500;
  font-size: 1.6rem;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  margin: 2.4rem 0 1.6rem 3.2rem;
  color: var(--text-color-white);
`;

export const BackButton = styled.a`
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #7a8296;
  text-decoration: none;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  transition: all 0.1s ease-out 0s;

  svg {
    margin-right: 0.6rem;
    width: 1.2rem;
    height: 1.2rem;
    fill: #7a8296;
    transform: rotate(180deg);
  }

  span {
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 1.69rem;
  }

  &:hover {
    cursor: pointer;
    color: #fff;

    svg {
      fill: #fff;
    }
  }
`;
