import * as yup from 'yup';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { ElevatedButton, OutlinedButton } from '.../../components/buttons';
import { createSpace } from '.../../features/spaces/stores/space.slice';
import { TextInput } from '.../../components/text-input/TextInput';
import { globalConfig } from '.../../configuration/config';
import { DescriptiveDropdown } from '.../../components/dropdowns/DescriptiveDropdown';
import { Form } from '.../../pages/on-boarding/components/form';
import { defaultStorageOptions } from '.../../pages/on-boarding/create-workspace.page';

type FormValues = {
  name: string;
  region: 'eu-west' | 'us-west' | 'custom';
};

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(.*)?\S+(.*)?$/, 'Field is Required')
    .required('Field is Required')
    .min(1),
  region: yup.string(),
});

type CreateSpaceProps = {
  setShowCreateSpace: (val: boolean) => void;
  activateSubscription: (spaceId: string) => void;
};

export const CreateSpace: React.FC<CreateSpaceProps> = ({
  setShowCreateSpace,
  activateSubscription,
}) => {
  const { t } = useTranslation('profile');
  const [isLoading, setIsLoading] = useState(false);

  const { register, handleSubmit, formState, control } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      region:
        globalConfig.config.storage_regions && globalConfig.config.storage_regions.length > 0
          ? 'custom'
          : 'eu-west',
    },
  });

  const handleOnSubmit = async (values: FormValues) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const space = await createSpace(values);

      await activateSubscription(space._id);
    } finally {
      setIsLoading(false);
    }
  };

  const options = globalConfig.config.storage_regions
    ? globalConfig.config.storage_regions
    : defaultStorageOptions;

  return (
    <Container>
      <p>To activate your subscription, you need to create a space</p>

      <Form id="create-space-form" onSubmit={handleSubmit(handleOnSubmit)}>
        <TextInput
          {...register('name', { required: true })}
          label="Space name"
          placeholder="Universal Pictures"
          helpText="It can be the name of your company or your team inside your organisation."
          errorMessage={formState.errors.name && t('common:fieldRequired')}
          required
        />

        <Controller
          name="region"
          control={control}
          render={({ field }) => (
            <DescriptiveDropdown
              label="Storage region"
              onChange={field.onChange}
              options={options}
              value={field.value}
            />
          )}
        />
      </Form>

      <ElevatedButton
        style={{ marginTop: '2.4rem' }}
        text="Activate"
        isLoading={isLoading}
        disabled={!formState.isValid}
        type="submit"
        form="create-space-form"
        isFullWidth
      />

      <OutlinedButton
        style={{ marginTop: '2.4rem' }}
        text="Back"
        onClick={() => setShowCreateSpace(false)}
        isFullWidth
        type="button"
      />
    </Container>
  );
};

const Container = styled.div`
  & > p {
    font-weight: 400;
    font-size: 1.4rem;
    color: var(--color-grayscale-manatee);
    margin-bottom: 2.4rem;
  }
`;
