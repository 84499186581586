import React, { PropsWithChildren, useState } from 'react';
import * as RadixTooltip from '@radix-ui/react-tooltip';
import styled from 'styled-components';

type TooltipProps = PropsWithChildren<{
  text: string;
  disabled?: boolean;
  position?: 'top' | 'bottom' | 'left' | 'right';
}>;

export const Tooltip: React.FC<PropsWithChildren<TooltipProps>> = ({
  children,
  position = 'top',
  text,
  disabled,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handleOpenChange = (open: boolean) => {
    if (disabled) {
      return setIsOpen(false);
    }

    setIsOpen(open);
  };

  return (
    <RadixTooltip.Provider delayDuration={0}>
      <RadixTooltip.Root open={isOpen} onOpenChange={handleOpenChange}>
        <RadixTooltip.Trigger asChild>{children}</RadixTooltip.Trigger>

        <RadixTooltip.Portal>
          <Content side={position}>
            {text}
            <RadixTooltip.Arrow />
          </Content>
        </RadixTooltip.Portal>
      </RadixTooltip.Root>
    </RadixTooltip.Provider>
  );
};

const Content = styled(RadixTooltip.Content)`
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 0.8rem 1rem;
  border-radius: 0.6rem;
  font-size: 1.2rem;
  font-weight: 600;
  z-index: var(--layer-tooltip);
  width: auto;
  white-space: nowrap;
  opacity: 1;
`;
