import { Route, Routes } from 'react-router-dom';
import { MarketplaceThanksPage } from './thanks.page';
import { MarketplaceLandingPage } from './landing.page';

export const MarketplaceLandingPageRouter = () => {
  return (
    <Routes>
      <Route path="/activation" element={<MarketplaceLandingPage />} />
      <Route path="/thanks" element={<MarketplaceThanksPage />} />
    </Routes>
  );
};
