import React, { useState } from 'react';
import { GroupBase, MultiValue, OptionsOrGroups, SingleValue, StylesConfig } from 'react-select';
import styled from 'styled-components';

import { ElevatedButton, OutlinedButton } from '.../../components/buttons';
import { Space } from '.../../app/entities/space';
import { authStore } from '../../../core/stores/auth-store';
import { AvatarSelect } from '../../../components/select/components/avatar-select/avatar-select';
import { AvatarOption } from '../../../components/select/components/avatar-select/avatar-select-option';

type SelectSpaceProps = {
  spaces: Space[];
  setSelectedSpace: (space: Space | undefined) => void;
  setShowCreateSpace: (val: boolean) => void;
  activateSubscription: (spaceId: string) => void;
  selectedSpace?: Space;
};

export const SelectSpace: React.FC<SelectSpaceProps> = ({
  spaces,
  setSelectedSpace,
  setShowCreateSpace,
  activateSubscription,
  selectedSpace,
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const isSpaceOwner = (space: Space) => {
    const currentUser = authStore.currentUser;
    const isOwner = space.ownerId === currentUser!._id;
    return isOwner;
  };

  const hasActiveSubscription = (space: Space) => {
    const hasActiveSubscription =
      space.subscription?.subscriptionId && space.subscription.status === 'active';
    return hasActiveSubscription;
  };

  const handleIsDisabled = (space: Space) => {
    if (hasActiveSubscription(space)) return true;

    if (!isSpaceOwner(space)) return true;

    return false;
  };

  const spaceOptions: OptionsOrGroups<AvatarOption, GroupBase<AvatarOption>> = spaces.map((el) => ({
    value: el._id,
    label: el.name,
    disabled: handleIsDisabled(el),
    avatarSrc: el.avatar,
    subtitle: !isSpaceOwner(el)
      ? 'You are not an admin or owner'
      : hasActiveSubscription(el)
      ? 'This space already has an active subscription'
      : '',
  }));

  const handleActivateSubscription = async () => {
    setIsLoading(true);

    await activateSubscription(selectedSpace!._id);

    setIsLoading(false);
  };

  const handleOnSelect = (option: SingleValue<AvatarOption> | MultiValue<AvatarOption>) => {
    if (!option) return;
    const space = spaces.find((el) => el._id === (option as AvatarOption).value);
    setSelectedSpace(space);
  };

  const selectStyles: StylesConfig<AvatarOption, boolean, GroupBase<AvatarOption>> = {
    menuList: (styles) => ({
      ...styles,
      backgroundColor: 'var(--color-grayscale-shark)',
      boxShadow: 'none',
      padding: '0.4rem',
    }),
    option: (styles, state) => ({
      ...styles,
      padding: 'unset',
      cursor: 'pointer',
      color: 'var(--color-grayscale-trout)',
      backgroundColor:
        state.isSelected || (state.isFocused && !state.isDisabled)
          ? 'var(--color-grayscale-arsenic)'
          : 'transparent',
      ':active': {
        backgroundColor:
          state.isSelected || (state.isFocused && !state.isDisabled)
            ? 'var(--color-grayscale-arsenic)'
            : 'transparent',
      },
      fontSize: '1.4rem',
      fontWeight: '500',
      lineHeight: 'normal',
      borderRadius: 'var(--border-radius-small)',
    }),
  };

  const handleDefaultValue = () => {
    return selectedSpace
      ? (spaceOptions.find((el: any) => el.value === selectedSpace._id) as AvatarOption)
      : undefined;
  };

  return (
    <Container>
      <AvatarSelect
        isMulti={false}
        isSearchable
        defaultValue={handleDefaultValue()}
        options={spaceOptions}
        onChange={handleOnSelect}
        label="Select space to connect to the plan"
        styles={selectStyles}
      />

      <ElevatedButton
        style={{ margin: '1.6rem 0' }}
        text="Activate"
        disabled={!selectedSpace}
        type="button"
        isLoading={isLoading}
        isFullWidth
        onClick={handleActivateSubscription}
      />
      <SeparatorWithWord>
        <span>or</span>
      </SeparatorWithWord>
      <OutlinedButton
        style={{ margin: '1.6rem 0' }}
        text="Create new space"
        onClick={() => setShowCreateSpace(true)}
        isFullWidth
        type="button"
      />
    </Container>
  );
};

const Container = styled.div`
  & button:last-child {
    border-color: var(--color-primary-crayola);
    color: var(--color-primary-crayola);

    &:hover,
    &:focus-visible {
      background-color: transparent;
    }
  }
`;

const SeparatorWithWord = styled.span`
  display: flex;
  flex-direction: row;

  &:before,
  &:after {
    content: '';
    flex: 1 1;
    border: 1px solid var(--color-grey-8);
    margin: auto;
  }

  span {
    padding: 0 1.6rem;
    font-size: 1.4rem;
    font-weight: 400;
    color: var(--color-grayscale-white);
  }
`;
