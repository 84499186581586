import React from 'react';
import styled from 'styled-components';

import { Help, Label, Error } from '../Input/styled-input';
import { KeyboardKeys } from '../../assets/enums/keyboard-keys.enum';

type TextInputProps = {
  value?: string;
  label?: string | null;
  onChange?: (e: any) => void;
  onKeyDown?: (e: any) => void;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  hasError?: boolean;
  disabled?: boolean;
  required?: boolean;
  dark?: boolean;
  errorMessage?: string | undefined | null;
  placeholder?: string | null;
  autocomplete?: string;
  name?: string;
  type?: string;
  defaultValue?: string;
  icon?: React.ReactNode;
  className?: string;
  helpText?: string;
  autoFocus?: boolean;
  suffix?: React.ReactNode;
};

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(function TextInput(
  {
    value,
    label,
    onChange,
    onFocus,
    hasError = false,
    errorMessage,
    icon,
    disabled = false,
    dark = true,
    onKeyDown,
    placeholder,
    type,
    name,
    required,
    onBlur,
    autocomplete,
    defaultValue,
    helpText,
    className,
    autoFocus,
    suffix,
  },
  ref,
) {
  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== KeyboardKeys.Escape) {
      e.stopPropagation();
    }

    if (onKeyDown) {
      onKeyDown(e);
    }
  };

  return (
    <TextInputContainer hasLabel={label !== undefined ? true : undefined} className={className}>
      {icon}
      {label && (
        <Label>
          {label} {required && <b>*</b>}
        </Label>
      )}

      {helpText && <Help>{helpText}</Help>}

      <Input
        ref={ref}
        value={value}
        onChange={onChange}
        onFocus={onFocus}
        hasError={hasError || !!errorMessage}
        hasIcon={!!icon}
        disabled={disabled}
        dark={dark}
        type={type}
        name={name}
        defaultValue={defaultValue}
        placeholder={placeholder || ''}
        onKeyDown={handleKeyDown}
        onBlur={onBlur}
        autoComplete={autocomplete}
        autoFocus={autoFocus}
      />

      {suffix && <Suffix>{suffix}</Suffix>}

      {errorMessage && <Error>{errorMessage}</Error>}
    </TextInputContainer>
  );
});

interface InputProps {
  hasError?: boolean;
  hasIcon?: boolean;
  dark?: boolean;
}

interface LabelProps {
  hasLabel?: boolean;
}

const TextInputContainer = styled.div<LabelProps>`
  position: relative;
  width: 100%;

  & > svg {
    position: absolute;
    left: 0.8rem;
    top: ${({ hasLabel }) => (hasLabel ? '2.5rem' : '.8rem')};
    bottom: 0.8rem;
    fill: white;
    z-index: 1;
    width: 1.6rem;
  }
`;

const Input = styled.input<InputProps>`
  position: relative;
  box-sizing: border-box;
  font-family: Inter;
  font-size: 1.4rem;
  line-height: normal;
  padding: ${({ hasIcon }) => (hasIcon ? '0 1.2rem 0 3.2rem' : '0 1.2rem')};
  transition: border 0.1s ease-in-out;
  background: var(--color-grayscale-tuna);
  width: 100%;
  border: 1px solid;
  border-radius: 0.4rem;
  height: 3.2rem;
  color: var(--color-grayscale-white);
  flex: 1;
  border-color: var(--color-grayscale-trout);
  font-weight: 400;

  &::placeholder {
    color: var(--color-grayscale-light-slate);
  }

  &:focus {
    border-color: ${({ hasError }) => (hasError ? 'var(--color-error)' : 'var(--color-secondary)')};
  }

  &:invalid {
    border-color: var(--color-error);
  }

  &:disabled {
    color: ${({ theme }) => theme.btnActiveColor.secondary};
  }
`;

const Suffix = styled.div`
  position: absolute;
  right: 0.8rem;
  bottom: 0.8rem;

  & > * {
    display: block;
  }
`;
