import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';

import { ElevatedButton } from '../../components/buttons';
import { Card } from '../../components/on-boarding/Card';
import { Form } from './components/form';
import { createSpace, fetchSpaces } from '../../features/spaces/stores/space.slice';
import { TextInput } from '../../components/text-input/TextInput';
import { globalConfig } from '../../configuration/config';
import { DescriptiveDropdown } from '../../components/dropdowns/DescriptiveDropdown';
import { authStore } from '../../core/stores/auth-store';

type FormValues = {
  name: string;
  region: 'eu-west' | 'us-west' | 'custom';
};

const schema = yup.object().shape({
  name: yup
    .string()
    .matches(/^(.*)?\S+(.*)?$/, 'Field is Required')
    .required('Field is Required')
    .min(1),
  region: yup.string(),
});

export const defaultStorageOptions = [
  {
    value: 'eu-west',
    label: 'Europe',
    description:
      'Your files will be stored in our european data center. Best choice for user in Europe, Asia, Middle-East and Africa.',
  },
  {
    value: 'us-west',
    label: 'North America',
    description:
      'Your files will be stored in our north-american data center. Best choice for user in United States, Canada, Mexico, and Australia.',
  },
  {
    value: 'brazil-south',
    label: 'South America',
    description:
      'Your files will be stored in our south american data center. Best choice for user in South America.',
  },
];

export const CreateWorkspacePage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation() as { state: { spaceId?: string; referrer?: string } };
  const { t } = useTranslation('profile');
  const [isLoading, setIsLoading] = useState(false);
  const user = authStore.currentUser;

  useEffect(() => {
    if (!user) {
      return;
    }

    fetchSpaces({ userId: user._id });
  }, [user]);

  const { register, handleSubmit, formState, control } = useForm<FormValues>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    resolver: yupResolver(schema),
    defaultValues: {
      region:
        globalConfig.config.storage_regions && globalConfig.config.storage_regions.length > 0
          ? 'custom'
          : 'eu-west',
    },
  });

  const handleOnSubmit = async (values: FormValues) => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      const payload = {
        ...values,
        ...(location.state.referrer && {
          referrer: location.state.referrer,
        }),
      };

      const space = await createSpace(payload);
      const state = { ...location.state, spaceId: space._id };
      setIsLoading(false);
      navigate('/onboarding/stats', { state });
    } catch (e) {
      setIsLoading(false);
    }
  };

  const options = globalConfig.config.storage_regions
    ? globalConfig.config.storage_regions
    : defaultStorageOptions;

  return (
    <Card
      title="Create your creative space"
      subtitle="Creative spaces are shared environments where teams can work on projects, manage contacts and share assets."
      footer={
        <ElevatedButton
          text="Create space"
          isLoading={isLoading}
          disabled={!formState.isValid}
          type="submit"
          form="create-space-form"
          isFullWidth
        />
      }
    >
      <Form id="create-space-form" onSubmit={handleSubmit(handleOnSubmit)}>
        <TextInput
          {...register('name', { required: true })}
          label="Space name"
          placeholder="Universal Pictures"
          helpText="It can be the name of your company or your team inside your organisation."
          errorMessage={formState.errors.name && t('common:fieldRequired')}
          required
        />

        <Controller
          name="region"
          control={control}
          render={({ field }) => (
            <DescriptiveDropdown
              label="Storage region"
              helpText="We believe that everybody should have control over their data. 
                            Choose in which region you would like us to store your media."
              onChange={field.onChange}
              options={options}
              value={field.value}
            />
          )}
        />
      </Form>
    </Card>
  );
};
